import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Partnerships.css";

function Services() {
  return ( 
    <>
    <div>
      <section className="partners-body partnership-lg d-flex justify-content-center align-items-center">
        <div className="overlay-title">
          <h1 className="fw-bold">OUR <span style={{ color: "#0167DB" }}><br></br>VALUED PARTNERS</span></h1>
        </div>
      </section>
    </div>

    <div className="partners-body-md">
      <h1 className="fw-bold">OUR <span style={{ color: "#0167DB" }}><br></br>VALUED PARTNERS</span></h1>
      <section className="partnership-md">
      </section>
    </div>

    <div className="partners-body-sm">
      <h1 className="fw-bold mb-5">OUR <span style={{ color: "#0167DB" }}><br></br>VALUED PARTNERS</span></h1>  
      <section className="partnership-sm">
      </section>
    </div>
    </> 
    
  );
}

export default Services;
