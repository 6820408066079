import React, { useEffect } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "./Footer.css";
import logo from "../../assets/logo2.png"
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEnvelope, faLocationDot} from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faFacebook,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

function Footer() {
  const location = useLocation();

  // Scroll to the section when the hash changes
  useEffect(() => {
    const sectionId = location.hash;
    if (sectionId) {
      const element = document.getElementById(sectionId.slice(1));  // Remove the '#' character
      if (element) {
        element.scrollIntoView(); // Scroll to the element
      }
    }
  }, [location]);
  return (
    <footer className="footer">
      {/* LG */}
      <section className="text-center d-flex flex-row footer-lg justify-content-between">
        <div className="left-side">
          <img src={logo} alt="Logo" className="img-logo"></img>
          <div className="link-box">
            <Link to="/#main-body">HOME</Link>
            <Link to="/services#service-body">SERVICES</Link>
            <Link to="/partners#partners-body">PARTNERS</Link>
            <Link to="/howItWorks#how-body">HOW IT WORKS</Link>
            <Link to="/about#about-body">ABOUT</Link>
            <Link to="/apply#main-hero-apply">APPLY NOW</Link>
            <p>
              <FontAwesomeIcon icon={faLocationDot} size="lg" className="me-1 ms-2" />
              4th Floor STEER Hub Bldg., BatStateU KIST Park Alangilan, <br/>{" "}
              <span className="ms-4">Batangas City 4200 Batangas, Philippines​​</span>
            </p>
            <p>
              <FontAwesomeIcon icon={faLocationDot} size="lg" className="me-1 ms-2" />
              7600 Dublin Blvd Ste 230 Dublin, CA 94568 
            </p>
          </div>
        </div>
        <div className="right-side">
          <h6>CONTACT US</h6> 
          <a href="mailto:info@fusionnode.ai" className="text-decoration-none text-light">
            <p className="email-p">
              <FontAwesomeIcon icon={faEnvelope} size="lg" className="me-1" />
              info@fusionnode.ai
            </p>
          </a>
          <div className="d-flex flex-row mt-3 mb-4">
            <a
              href="https://www.facebook.com/profile.php?id=61560821002326"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light"
            >
              <FontAwesomeIcon icon={faFacebook} size="xl" className="me-3" />
            </a>
            <a
              href="https://www.instagram.com/fusionnode/?utm_source=ig_web_button_share_sheet"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light"
            >
              <FontAwesomeIcon icon={faInstagram} size="xl" className="me-3" />
            </a>
            <a
              href="https://www.linkedin.com/company/fusionnode/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light"
            >
              <FontAwesomeIcon icon={faLinkedin} size="xl" className="me-3" />
            </a>
          </div>
          <Link to="/privacy#main-title" className="click-privacy text-decoration-none text-light">
            <p className='privacy-text'>Privacy Policy</p>
          </Link>
        </div>
      </section>
      {/* MD */}
      <section className="footer-md">
        <div className="left-side">
            <img src={logo} alt="Logo" className="img-md-logo"></img>
            <div className="link-box">
              <Link to="/#main-body">HOME</Link>
              <Link to="/services#service-body">SERVICES</Link>
              <Link to="/partners#partners-body">PARTNERS</Link>
              <Link to="/howItWorks#how-body">HOW IT WORKS</Link>
              <Link to="/about#about-body">ABOUT</Link>
              <Link to="/apply#main-hero-apply">APPLY NOW</Link>
              <a href="mailto:info@fusionnode.ai" className="text-decoration-none text-light mb-0">
                <p className="email-p mb-0">
                  <FontAwesomeIcon icon={faEnvelope} size="lg" className="me-1" />
                  info@fusionnode.ai
                </p>
              </a>
              <p className="mt-0">
              <FontAwesomeIcon icon={faLocationDot} size="lg" className="me-1" />
                4th Floor STEER Hub Bldg., BatStateU KIST Park Alangilan, <br />{" "}
                Batangas City 4200 Batangas, Philippines​​
              </p>
              <p>
                <FontAwesomeIcon icon={faLocationDot} size="lg" className="me-1" />
                7600 Dublin Blvd Ste 230 Dublin, CA 94568 
              </p>
              <p className="mb-3">Copyright © 2024 FusionNode - All Rights Reserved.</p>
            <a
              href="https://www.facebook.com/profile.php?id=61560821002326"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light"
            >
              <FontAwesomeIcon icon={faFacebook} size="xl" className="me-3" />
            </a>
            <a
              href="https://www.instagram.com/fusionnode/?utm_source=ig_web_button_share_sheet"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light"
            >
              <FontAwesomeIcon icon={faInstagram} size="xl" className="me-3" />
            </a>
            <a
              href="https://www.linkedin.com/company/fusionnode/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light"
            >
              <FontAwesomeIcon icon={faLinkedin} size="xl"/>
            </a>
            <Link to="/privacy#main-title" className="click-privacy text-decoration-none text-light"><p className="mt-3">Privacy Policy</p></Link>
            </div>
          </div>
      </section>
      {/* SM */}
      <section className="footer-sm">
        <div className="text-center justify-content-center">
          <img src={logo} alt="Logo" className="img-sm-footer"></img>
        </div>
        <div className="row g-0 link-box text-center">
            <Link to="/#main-body">HOME</Link>
            <Link to="/services#service-body">SERVICES</Link>
            <Link to="/partners#partners-body">PARTNERS</Link>
            <Link to="/howItWorks#how-body">HOW IT WORKS</Link>
            <Link to="/about#about-body">ABOUT</Link>
            <Link to="/apply#main-hero-apply">APPLY NOW</Link>
          </div>
      </section>
      <div className="bottom-part d-flex flex-row justify-content-between footer-lg">
        <p>Copyright © 2024 FusionNode - All Rights Reserved.</p>
      </div>
      <div className="bottom-part footer-sm justify-content-center m-auto">
        <p style={{fontSize: "13px"}}>
          <FontAwesomeIcon icon={faLocationDot} size="lg" className="me-1" />
            4th Floor STEER Hub Bldg., BatStateU KIST Park Alangilan, <br />{" "}
            Batangas City 4200 Batangas, Philippines​​
        </p>
        <p style={{fontSize: "13px"}}>
          <FontAwesomeIcon icon={faLocationDot} size="lg" className="me-1" />
          7600 Dublin Blvd Ste 230 Dublin, CA 94568 
        </p>
        <p>Copyright © 2024 FusionNode - All Rights Reserved.</p>
      <div>
      <a
              href="https://www.facebook.com/profile.php?id=61560821002326"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light"
            >
              <FontAwesomeIcon icon={faFacebook} size="xl" className="me-3" />
            </a>
            <a
              href="https://www.instagram.com/fusionnode/?utm_source=ig_web_button_share_sheet"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light"
            >
              <FontAwesomeIcon icon={faInstagram} size="xl" className="me-3" />
            </a>
            <a
              href="https://www.linkedin.com/company/fusionnode/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light"
            >
              <FontAwesomeIcon icon={faLinkedin} size="xl"/>
            </a>
            </div>
            <Link to="/privacy#main-title" className="click-privacy text-decoration-none text-light">
              <p className='privacy-text'>Privacy Policy</p>
            </Link>
          </div>
    </footer>
  );
}

export default Footer;
