import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./AboutForm.css";
import contact from "../../assets/contact.png";
import { useForm } from "react-hook-form";
import axios from 'axios';
import Swal from "sweetalert2";

function AboutForm() {
  const { register, getValues, reset, formState: { errors }, trigger } = useForm();
  
  const onSubmit = async () => {
    try {
      const formdata = getValues();
      const { full_name, email_address, org_name, query } = formdata;

      // Form submission
      await axios.post("https://prod-64.westus.logic.azure.com:443/workflows/ce272e6cdbc541049378d4efb38fa18c/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=GtWvgFPExPNlY5EsaGpCZkq1ubfw-s4qIJbfJ0nHCMA",
       {
        "full_name": full_name,
        "email_address": email_address,
        "org_name": org_name,
        "query": query
       });

       reset();
       Swal.fire({
         title: "Success!",
         text: "Your submission has been completed.",
         icon: "success",
       });
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Your submission has not been completed. Please try again.",
        icon: "error",
      });
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault(); // Prevents default form submission

    // Manually trigger validation for all fields
    const isValid = await trigger();
    
    if (isValid) {
      // Show popup for confirmation only if the form is valid
      Swal.fire({
        title: "Confirm Submission",
        text: "Are you sure you want to submit this form? Please review your information before proceeding.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Submit",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          // If the user confirms, then call the form submit function
          onSubmit();
        } else {
          Swal.fire({
            title: "Cancelled",
            text: "Your submission has been cancelled.",
            icon: "error",
          });
        }
      });
    } else {
      // Show an error message if required fields are missing
      Swal.fire({
        title: "Form Incomplete",
        text: "Please fill out all required fields before submitting.",
        icon: "error",
      });
    }
  };

  return (
    <div className="about-form">
      <section className="about-section d-flex flex-column">
        <h1 className="mb-3 text-light mt-5 fw-bold">GET IN <span style={{ color: "#92c8ff" }}>TOUCH</span></h1>
        <p className="about-p fs-6 text-light mb-4 text-center">
          With decades of combined global business, IT, and cybersecurity experience, our team of business, IT, and cybersecurity professionals are dedicated to safeguarding clients' intellectual property and privacy. We provide cutting-edge solutions to ensure Client Organization stays competitive and ahead of the evolving cyber threat landscape.
        </p>
      </section>
      <form action="#" onSubmit={handleFormSubmit} className="second-part">
        <div className="img-box w-100">
          <img src={contact} alt="Form" className="w-50" />
        </div>
        <div className="input-box w-100">
          <input 
            type="text" 
            id="full_name" 
            className="fullName" 
            placeholder="Full Name" 
            {...register("full_name", { required: true, maxLength: 70 })}
            aria-invalid={errors.full_name ? "true" : "false"}
            autoComplete="off"
          />
          {errors.full_name && <span role='alert' className="text-danger">Name is required.</span>}
          
          <input
            type="email"
            id="email_address"
            className="businessMail"
            placeholder="Business Email"
            {...register("email_address", { required: true, pattern: /^\S+@\S+$/ })}
            aria-invalid={errors.email_address ? "true" : "false"}
            autoComplete="off"
          />
          {errors.email_address && <span role="alert" className="text-danger">Business Email is required.</span>}
          
          <input
            type="text"
            id="org_name"
            className="orgName"
            placeholder="Organization Name"
            {...register("org_name", { required: true, maxLength: 70 })}
            aria-invalid={errors.org_name ? "true" : "false"}
            autoComplete="off"
          />
          {errors.org_name && <span role="alert" className="text-danger">Organization Name is required.</span>}
          
          <textarea 
            id="query" 
            className="query" 
            placeholder="Query"
            {...register("query", { required: true })}
            aria-invalid={errors.query ? "true" : "false"}
            autoComplete="off"
          />
          {errors.query && <span role="alert" className="text-danger">Query is required.</span>}
          
          <button type="submit">SUBMIT</button>
        </div>
      </form>
    </div>
  );
}

export default AboutForm;
