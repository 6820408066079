import "../../App.css";
import "./Newsletter.css";
import Swal from "sweetalert2";
import axios from "axios";
import { useState } from "react";

function Newsletter() {
  // State for managing email input in the modal
  const [email, setEmail] = useState("");

  // Handle form submission and send email via axios
  const handleFormSubmit = async () => {


    try {
      // Send email via axios
      await axios.post(
        "https://prod-07.westus.logic.azure.com:443/workflows/516ecb6f084b4587a0fb534f4fc9643f/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=a8gJIfobHemMW2TMv1KcRG30DMtZA0ovGHJHKbGU3Ms",
        { email }
      );

      // Success message
      Swal.fire({
        title: "Success!",
        text: "Your subscription was successful.",
        icon: "success",
      });

      // Clear email after submission
      setEmail("");
    } catch (error) {
      // Error message
      Swal.fire({
        title: "Error!",
        text: "There was an issue with your subscription. Please try again.",
        icon: "error",
      });
    }
  };

  // Open the SweetAlert modal when the Subscribe button is clicked
  const openModal = () => {
    Swal.fire({
      title: "Subscribe to our Newsletter!",
      text: "Enter your email address to get updates.",
      input: "email",
      inputValue: email, // Pre-fill input with current email value
      inputPlaceholder: "Enter your email",
      showCancelButton: true,
      confirmButtonText: "Subscribe",
      cancelButtonText: "Cancel",
      inputValidator: (value) => {
        if (!value) {
          return "You need to enter an email address!";
        }
        setEmail(value); // Update email state when the user enters an email
        return null;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleFormSubmit(); // Submit the form when the user clicks "Subscribe"
      }
    });
  };

  return (
    <>
      <div className="d-flex flex-row justify-content-center align-items-center newsletter nl-lg">
        <div>
          <h2 className="newsletter-text">Get updates and Newsletter from FusionNode!</h2>
        </div>
        <div>
          {/* Only one Subscribe button that opens the modal */}
          <button className="newsletter-button" onClick={openModal}>
            Subscribe
          </button>
        </div>
      </div>

      <div className="newsletter nl-sm">
        <div className="d-flex flex-column justify-content-center">
          <h2 className="newsletter-text text-center">
            Get updates and Newsletter from FusionNode!
          </h2>
          <button className="newsletter-button mt-3 mx-auto" onClick={openModal}>
            Subscribe
          </button>
        </div>
      </div>
    </>
  );
}

export default Newsletter;
