import React, { useState } from "react";
import "./Apply.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Card, Col } from "react-bootstrap";
import NavigationBar from "../../components/NavBar/Navbar";
import AboutForm from "../../components/AboutForm/AboutForm";
import Subs from "../../components/Newsletter/Newsletter";
import Footer from "../../components/Footer/Footer";
import applyJson from "./Apply.json";
import { Link } from "react-router-dom";
 
function Apply() {
    const [job] = useState(applyJson);
    return (
        <>
            <NavigationBar/>
            <div className="hero-apply" id="main-hero-apply">

            </div>
            <div className="cards-container">
            {/* JD Cards */}
            <div className="d-flex justify-content-center align-items-center">
            <Row className="job-row d-flex justify-content-center align-items-center g-5">
                    {job.map((job, i) => (
                        <Col
                            key={i}
                            xs = {12}
                            md = {12}
                            lg = {9}
                            className="d-flex justify-content-center align-items-center my-3"
                        >
                        <Card className="job-cards">
                            <Card.Body className="job-card-body">
                                <div className="top-part">
                                    <h5 className="job-title text-dark fw-bold">{job.title}</h5>
                                    <p className="job-p">{job.description}</p>
                                </div>
                                <div className="bottom-part">
                                    <Link to="/jobDescription#main-hex-bg" state={{ title: job.title }} className="text-decoration-none">
                                        <button className="view-btn btn btn-primary ms-2 ">View Details</button>
                                    </Link>
                                </div>
                            </Card.Body>
                        </Card>
                        </Col>
                    ))}
                </Row>
            </div>  
            </div>
            <AboutForm/>
            <Subs/>
            <Footer/>
        </>
    );
}

export default Apply;