import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Educational.css";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Swal from "sweetalert2";
import educationJson from "./Educational.json";

function Educational() {
  const [educational] = useState(educationJson);

  const handleLearnMore = (educational) => {
    // Create the HTML for list items
    const moreItemsHTML = educational.more
      .map((item) => `<li>${item}</li>`)
      .join("");
  
    Swal.fire({
      html: ` 
        <div style="text-align: center;">
          <div class="mb-2 educational-imgbg" style="display: inline-flex; align-items: center; justify-content: center;">
            <img src="${require(`../../../assets/Partners/${educational.imgicon}.webp`)}" alt="Educational" class="educational-img"/>
          </div>
          <h3 style="font-weight: bold; margin-top: 10px;">${educational.title}</h3>
          <ul class="educ-p text-dark fs-6 text-start">
            ${moreItemsHTML}
          </ul>
        </div>
      `,
      showConfirmButton: false,
      showCloseButton: true,
      closeButtonHtml: '&times;',
      customClass: {
        popup: 'educational-popup',
        closeButton: 'educational-swal-close-button'
      }
    });
  };
  

  return (
    <div>
      <section className="d-flex flex-column align-items-center justify-content-center">
        {/* CARDS */}
        <div className="container-fluid">
          <section className="educational-sections">
            <h1 className="fw-bold ms-5 mb-4 mt-5">EDUCATIONAL PARTNERS</h1>
          </section>
          <Row className="gx-0 g-5 mt-1 mb-5 justify-content-start align-items-center mb-5 my-1 mx-3">
            {educational.map((educational, i) => (
              <Col key={i} xs={12} md={6} lg={4} className="d-flex justify-content-center align-content-center my-2">
                <Card className="educationalCards mx-sm-auto mx-md-auto">
                  <div className="mx-3 d-flex flex-row">
                    <div className="d-flex mt-3">
                      <div className="educational-imgbg me-3">
                        <img src={require(`../../../assets/Partners/${educational.imgicon}.webp`)} alt="Educational" className="educational-img" />
                      </div>
                    </div>
                    <div className="my-3">
                      <p className="fw-bold mb-1">{educational.title}</p>
                      <p className="educational-para text-start mb-1">{educational.info}</p>
                      <button className="learn-btn w-50" onClick={() => handleLearnMore(educational)}>Learn More</button>
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </section>
    </div>
  );
}

export default Educational;
