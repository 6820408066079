import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Error.css";
import ErrorImgLG from "../../assets/Error.webp";
import ErrorImgSM from "../../assets/ErrorMobile.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Error() {
    return (
        <>
            <div className="error-lg">
            <div className="overlay-text">
                <h1 style={{color: "#0167DB"}}>Oops!</h1>
                <h1>Looks like</h1>
                <h1>you're lost...</h1>
                <button className="home-btn">
                    <Link to="/" className="text-decoration-none text-dark">
                        <FontAwesomeIcon icon={faChevronLeft} size="sm" className="me-2" style={{color: "#007bff"}} />
                        Back to Home
                    </Link>
                </button>
            </div>
                <img src={ErrorImgLG} alt="Error Page" className="error-image"/>
            </div>

            <div className="error-md">
            <div className="overlay-text">
                <h1 style={{color: "#0167DB"}}>Oops!</h1>
                <h1>Looks like</h1>
                <h1>you're lost...</h1>
                <button className="home-btn">
                    <Link to="/" className="text-decoration-none text-dark">
                        <FontAwesomeIcon icon={faChevronLeft} size="sm" className="me-2" style={{color: "#007bff"}} />
                        Back to Home
                    </Link>
                </button>
            </div>
                <img src={ErrorImgLG} alt="Error Page" className="error-image"/>
            </div>

            <div className="error-sm">
            <div className="overlay-text">
                <h1 style={{color: "#0167DB"}}>Oops!</h1>
                <h1>Looks like</h1>
                <h1>you're lost...</h1>
                <button className="home-btn">
                    <Link to="/" className="text-decoration-none text-dark">
                        <FontAwesomeIcon icon={faChevronLeft} size="sm" className="me-2" style={{color: "#007bff"}} />
                        Back to Home
                    </Link>
                </button>
            </div>
                <img src={ErrorImgSM} alt="Error Page" className="error-image"/>
            </div>
        </>
    )
}

export default Error;