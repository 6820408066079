import React, { useState } from "react";
import "./JobDesc.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NavigationBar from "../../components/NavBar/Navbar";
import AboutForm from "../../components/AboutForm/AboutForm";
import Subs from "../../components/Newsletter/Newsletter";
import Footer from "../../components/Footer/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuildingUser, faListCheck, faUserTie, faCalendarCheck, faUserShield, faNetworkWired } from "@fortawesome/free-solid-svg-icons";
import applyJson from "../ApplyNow/Apply.json";
import { useLocation, Link } from "react-router-dom";

function JobDescription() {
    const location = useLocation();
    const { title } = location.state || {}; // Destructure title from state
    const [job] = useState(applyJson);

    // Find the job matching the passed title
    const selectedJob = job.find((jobItem) => jobItem.title === title);

    const iconMap = {
        faBuildingUser,
        faListCheck,
        faUserTie,
        faCalendarCheck,
        faUserShield,
        faNetworkWired
      };

    return (
        <>
            <NavigationBar/>
            <div id="main-hex-bg">
            <div className="main-job-container">
                <div className="jobDescription-container">
                    {selectedJob ? (
                        <>
                            <section className="titles">
                                <h2 className="mb-0 fw-bold">{selectedJob.title}</h2>
                                <p>Date Posted: {selectedJob.date}</p>
                                <div className="categories mx-1">
                                    <div className="category-list">
                                        {selectedJob.category.map((cat, index) => (
                                            <div
                                                key={index}
                                                className="category mb-2"
                                            >
                                                <FontAwesomeIcon
                                                        icon={iconMap[cat.icon]}
                                                        alt="Category"
                                                        className="mx-1"
                                                        color="#0167DB"
                                                    />
                                                    <span>{cat.label}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </section>
                            <section className="jobDescription mx-1 mt-4">
                                <h5 className="fw-bold">Position Overview</h5>
                                <h6 className="fw-normal text-justify">{selectedJob.jobDescription}</h6>
                            </section>
                            <section className="responsibilities mx-1 mt-4">
                                <h5 className="fw-bold">Responsibilities</h5>
                                {selectedJob.responsibilities.map((respGroup, groupIndex) => (
                                    <div key={groupIndex} className="responsibility-group">
                                        {/* Display the first item as the title */}
                                        <h6 className="fw-bold">{respGroup[0]}</h6>
                                        {/* Display the rest as a list */}
                                        <ul className={respGroup[0] ? "" : "mb-0 mt-0"}>
                                            {respGroup.slice(1).map((item, itemIndex) => (
                                                <li key={itemIndex} className="">{item}</li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </section>
                            <section className="requirements mx-1 mt-4">
                                <h5 className="fw-bold">Requirements</h5>
                                <ul>
                                    {(selectedJob.requirements || []).map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </section>

                            <section className="qualifications mx-1 mt-4">
                                <h5 className="fw-bold">Preferred Qualifications</h5>
                                <ul>
                                    {(selectedJob.qualifications || []).map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </section>

                            <section className="about-us mt-5">
                                <h5 className="fw-bold">About Us</h5>
                                <h6 className="fw-normal text-justify">FusionNode is a US-based Managed Security Service Provider (MSSP) company committed to safeguarding our clients’ intellectual property and privacy. Our collaborative and innovative environment fosters excellence, making FusionNode a trusted name in cybersecurity. As we expand our operations in Batangas City, we are seeking an organized and dynamic HR and Office Coordinator to support our growing team.</h6>
                                <h5 className="fw-bold mt-4">Why Join Us?</h5>
                                <h6 className="fw-normal text-justify">At FusionNode, we value teamwork, integrity, and innovation. This role offers a unique opportunity to contribute to the growth and success of our company while supporting a vibrant and collaborative office environment.</h6>
                            </section>
                            <section className="d-flex justify-content-start align-items-start mt-4">
                                <Link to={selectedJob.formLink} className="text-decoration-none">
                                    <button className="apply-btn mt-1">APPLY</button>
                                </Link>
                            </section>
                        </>
                    ) : (
                        <p>Job not found</p>
                    )}
                </div>
            </div>
            </div>
            <AboutForm />
            <Subs />
            <Footer />
        </>
    );
}

export default JobDescription;
